import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import ProgramGeneralInfo from "../components/programGeneralInfo";
import ProgramCostTable from "../components/programCostTable";
import Textbooks from "../components/textbooks";

import barberImage1 from "../images/barber-1.jpeg";
import barberImage2 from "../images/barber-2.jpeg";
import barberImage3 from "../images/barber-3.jpeg";
import barberImage4 from "../images/barber-4.jpeg";
import barberHero from "../images/barber-hero.jpg";

const content = {
	title: "Restricted Barber Program",
	paragraphs: [
		"The Restricted Barber Program (RBAR) is taught in English. The Restricted Barber program course includes study in Florida Laws & Rules, safety, sterilization and sanitation, hair structure, cutting and cleansing, shaving, beard & Mustache trimming. Students are required to complete 150 hours before being allowed to service clients on the clinical floor. Upon graduation students are required to demonstrate live model performances and complete the required HIV/AIDS education exam.",
		"The 600 hour Restricted Barber course prepares all graduates for the Florida State Board of Barber Examination to become licensed Restricted barbers. The students are trained in the basic manipulative skills, safety judgments, good work habit, business skills, and professional ethics as well as to take and pass the state licensing examination.  Upon completion of the Restricted Barber Program, the student will receive a diploma and be prepared to obtain entry-level employment in the barbering industry.",
	],
	images: {
		heroImage: barberHero,
		contentImages: [barberImage1, barberImage2, barberImage3, barberImage4],
	},
};

const costsObj = {
	title: "Restricted Barber Learning",
	tuition: "$9,000.00",
	appFees: "$50.00",
	regFees: "$100.00",
	supplies: "$1,550.00",
	stateFees: "$255.00",
	hivFees: "$20.00",
	totalCost: "$10,975.00",
	totalHours: "600 Hours",
};

const textBooks = [
	{
		bookName:
			"Milady’s CIMA for Barbering 6th Edition ISBN: 9780357812587 - $299.95",
		bookLink:
			"https://www.google.com/search?q=Milady%E2%80%99s+CIMA+for+Barbering+6th+Edition+ISBN%3A+9780357812587&oq=Milady%E2%80%99s+CIMA+for+Barbering+6th+Edition+ISBN%3A+9780357812587&aqs=chrome.0.69i59.205j0j1&sourceid=chrome&ie=UTF-8",
	},
	{
		bookName: "Online Licensing Preparation-ISBN: 9780357920237 - $29.96",
		bookLink:
			"https://www.google.com/search?q=Online+Licensing+Preparation-ISBN%3A+9780357920237&oq=Online+Licensing+Preparation-ISBN%3A+9780357920237&aqs=chrome..69i57.389j0j1&sourceid=chrome&ie=UTF-8",
	},
	{
		bookName: "1 Samsung Chrome Book - $300.00",
		bookLink: "javascript:void(0)",
	},
	{
		bookName: "Onetonline.org - SOC CODE: 39-5011",
		bookLink: "https://www.onetonline.org/link/summary/39-5011.00",
	},
	{
		bookName: "CIP CODE: 12.0402",
		bookLink: "javascript:void(0)",
	},
];

const BarberProgram = () => (
	<Layout>
		<SEO title="Restricted Barber Program" />
		<br />
		<ProgramGeneralInfo content={content} />
		<ProgramCostTable costs={costsObj} />
		<Textbooks books={textBooks} />
	</Layout>
);

export default BarberProgram;
